import { ReactElement, cloneElement } from 'react';
import { ExternalLink, InternalLink, NavMenu, Socials } from 'components';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'routes';

function ElevationScroll({ children }:{ children: ReactElement}) {

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
}

export const TopMenu = () => {
    return (
        <ElevationScroll>
            <AppBar 
                position="sticky" 
                sx={{ 
                    backgroundColor: '#493935', 
                }}
            >
                <Toolbar>
                    <InternalLink to={ROUTES.home}>
                        <Typography fontWeight='bolder'>Nerdherfers</Typography>
                    </InternalLink>
                    <Box sx={{ flexGrow: 1 }} pl={3}>
                           <Socials color='#ffffff'/> 
                    </Box>
                    <Box sx={{ flexGrow: 0 }} pr={3}>
                        <ExternalLink to="https://nerdherfers.etsy.com">Shop</ExternalLink>
                    </Box>
                    <Box>
                        <NavMenu/>
                    </Box>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}
