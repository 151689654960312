import Stack from '@mui/material/Stack';
import { CdnImage, ExternalLink } from '.';

const platforms = [{
    name: 'Amazon Music',
    link: 'https://music.amazon.com/podcasts/f6fbbcc8-9198-4f97-89c9-331a682f7856',
    icon: 'AmazonMusic.png',
},{
    name: 'Listen Notes',
    link: 'https://lnns.co/BIaofZ1lnph',
    icon: 'ListenNotes.png',
},{
    name: 'PlayerFM',
    link: 'https://player.fm/series/3555995',
    icon: 'PlayerFM.png',
},{
    name: 'Podchaser',
    link: 'https://www.podchaser.com/podcasts/nerdherfers-5642922',
    icon: 'Podchaser.webp',
},{
    name: 'Apple Podcasts',
    link: 'https://podcasts.apple.com/us/podcast/nerdherfers/id1734324193',
    icon: 'apple-podcast.png',
},{
    name: 'iHeartRadio',
    link: 'https://iheart.com/podcast/156293426',
    icon: 'iHeartRadio.png',
},{
    name: 'Podbean',
    link: 'https://r4j68.app.goo.gl/?link=https%3A%2F%2Fnerdherfers.podbean.com%3Faction%3DopenPodcast%26podcastId%3Dpbblog18101397%26podcastIdTag%3Dgwgkq&dfl=https%3A%2F%2Fnerdherfers.podbean.com&utm_campaign=pb_web&utm_source=podbean_web&utm_medium=dlink&imv=670&amv=530&isi=973361050&ibi=com.podbean.app.podcast&ipbi=com.podbean.app.podcast&apn=com.podbean.app.podcast',
    icon: 'podbean-app.png',
},{
    name: 'Spotify',
    link: 'https://open.spotify.com/show/5dkMShhvtY5Q2tGvtgjfJn',
    icon: 'spotify.png',
},{
    name: 'Youtube',
    link: 'https://www.youtube.com/playlist?list=PLbnzJu9t0ZUCjOQpIzPz_tJ1EwlYVEnj6',
    icon: 'youtube_logo.png',
}];

export const Platforms = () => {
    return (
        <Stack direction="row" spacing={2}>
            {platforms.map(({ name, link, icon }) => (
                <ExternalLink to={link}>
                    <CdnImage alt={name} src={`icons/${icon}`} height={32}/>
                </ExternalLink>
            ))}
        </Stack>
    )
}