import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { InternalLink } from 'components';
import { ROUTES } from 'routes';
import Divider from '@mui/material/Divider';
import { Socials } from '.';
import Typography from '@mui/material/Typography';

export const PageFooter = () => (
    <Box
        flexGrow={0}
        sx={{ 
            color: 'white',
            backgroundColor: "#493935",
            padding: '20px',
            fontSize: { 
                md: 'initial',
                xs: '12px'
            }
        }}
        width='100%'
        textAlign='center'
    >
        <Divider/>
        <Typography 
            mt={1}
            display={{ xs: 'block', md: 'none' }}
            fontSize='12px'
        >
            &copy;2024 Nerdherfers
        </Typography>
        <Stack 
            direction="row" 
            columnGap={2} 
            display="flex" 
            flexDirection="row" 
            justifyContent='center'
            sx={{ padding: '10px' }}
            width='100%'
        >
            <Typography display={{ xs: 'none', md: 'block' }}>&copy;2024 Nerdherfers |</Typography>
            <span><InternalLink to={ROUTES.episodes}>Episodes</InternalLink></span> | 
            <span><InternalLink to={ROUTES.about}>About</InternalLink></span> | 
            <span><InternalLink to={ROUTES.disclaimer}>Disclaimer</InternalLink></span> | 
            <span><InternalLink to={ROUTES.contact}>Contact Us</InternalLink></span> | 
            <span><InternalLink to={ROUTES.ringtones}>Ringtones</InternalLink></span>
        </Stack>
        <Box 
            display='flex'
            flexDirection='row'
            justifyContent='center'
        >
            <Socials color='#ffffff'/>
        </Box>
    </Box>
)

/*
This has been a production of Nerdherfers and is Copyright 2024, all rights reserved. 
No part of this broadcast may be used without permission. 
Please visit nerdherfers.com for more information and full legal disclaimer.
*/

/*
Music from #Uppbeat (free for Creators!):
https://uppbeat.io/t/danijel-zambo/game-over
License code: YH0F3AGDNAKKTYSG
*/